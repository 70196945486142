<template>
    <DxToolbar style="height: 56px" class="toolbar">
        <DxItem location="before" locate-in-menu="never">
            <template #default>
                <routerVue :breadcrumbsItems="breadcrumbsItems" />
            </template>
        </DxItem>
    </DxToolbar>
    <div class="row">
        <div class="xs12" style="padding: 6px 0px 6px 24px">
            <DxButton
                type="normal"
                icon="mdi mdi-refresh"
                styling-mode="contained"
                hint="Tải lại"
                @click="loadData()"
            />
            <DxButton
                type="normal"
                icon="mdi mdi-plus-circle"
                styling-mode="contained"
                class="ml-2"
                @click="ThemLoaiHoaDon()"
                hint="Thêm mới"
                v-if="
                    $Helper.Common.CheckRole(
                        $t('Quyen.CauHinh'),
                        $t('Quyen.ThemSuaLoaiHoaDonDoanhNghiepSuDung')
                    )
                "
            />
            <DxButton
                type="normal"
                icon="mdi mdi-pencil"
                styling-mode="contained"
                class="ml-2"
                @click="SuaLoaiHoaDon(this.arrSelected[0])"
                :disabled="arrSelected.length != 1"
                hint="Sửa"
                v-if="
                    $Helper.Common.CheckRole(
                        $t('Quyen.CauHinh'),
                        $t('Quyen.ThemSuaLoaiHoaDonDoanhNghiepSuDung')
                    )
                "
            />
            <DxButton
                type="normal"
                icon="mdi mdi-delete"
                styling-mode="contained"
                class="ml-2"
                :disabled="arrSelected.length != 1"
                @click="XoaLoaiHoaDon(arrSelected[0])"
                hint="Xóa"
                v-if="
                    $Helper.Common.CheckRole(
                        $t('Quyen.CauHinh'),
                        $t('Quyen.XoaLoaiHoaDonDoanhNghiepSuDung')
                    )
                "
            />
            <!-- :disabled="arrSelected.length == 0" -->
            <DxButton
                type="normal"
                icon="mdi mdi-file-excel"
                styling-mode="contained"
                class="ml-2"
                @click="exportExcel"
                hint="Xuất Excel"
            />
        </div>
    </div>

    <div class="row pl-4 pr-4">
        <div class="xs12">
            <DxDataGrid
                id="gridContainer"
                class="table-page"
                :remote-operations="false"
                :show-borders="true"
                :data-source="DanhSachLoaiHoaDonDoanhNghiepSuDung"
                :allow-column-resizing="true"
                :column-auto-width="true"
                :show-row-lines="true"
                ref="DanhSachLoaiHoaDonDoanhNghiepSuDung"
                :loadPanel="{ showIndicator: false, showPane: false, text: '' }"
                no-data-text="Không có dữ liệu!"
                height="calc(100vh - 56px - 48px - 16px)"
                @cellHoverChanged="onCellHoverChanged"
                @selection-changed="onSelectionChanged"
            >
                <DxHeaderFilter :visible="true" :allow-search="true" />
                <DxPaging :page-size="50" />
                <DxScrolling mode="virtual" row-rendering-mode="virtual" />
                <DxColumnFixing :enabled="true" />
                <DxFilterRow :visible="true" />
                <DxSelection
                    select-all-mode="allPages"
                    show-check-boxes-mode="always"
                    mode="multiple"
                />
                <DxColumn
                    data-field="tenLoaiHoaDon"
                    caption="Tên loại hóa đơn"
                    alignment="left"
                />

                <DxColumn data-field="domain" caption="Tên miền" alignment="left" />
                <!-- :group-index="0" -->
                <DxColumn
                    data-field="linkTraCuu"
                    alignment="left"
                    caption="Link tra cứu"
                />
                <DxColumn data-field="maSoThue" alignment="left" caption="Mã số thuế" />
                <DxColumn data-field="taiKhoan" alignment="left" caption="Tài khoản" />
                <DxColumn
                    data-field="matKhau"
                    alignment="left"
                    caption="Mật khẩu"
                    cellTemplate="tmpMatKhau"
                />
                <template #tmpMatKhau="{ data }">
                    {{ data.data.matKhau.replace(/./g, "*") }}
                </template>
                <DxColumn
                    data-field="thoiGianDelayLayBanTheHien"
                    alignment="left"
                    caption="Thời gian delay lấy bản thể hiện (ms)"
                />

                <DxSummary>
                    <DxTotalItem
                        column="tenLoaiHoaDon"
                        summary-type="count"
                        display-format="Tổng: {0}"
                        :show-in-group-footer="true"
                        :align-by-column="true"
                    />
                </DxSummary>
                <!-- Hover button -->
                <DxColumn
                    cssClass="column-button-hover"
                    data-field="Detail"
                    caption="Trạng thái"
                    width="1"
                    cell-template="buttons-cell"
                    :fixed="true"
                    fixed-position="right"
                ></DxColumn>
                <template #buttons-cell="{ data }">
                    <div class="buttons">
                        <DxButton
                            id=""
                            icon="mdi mdi-pencil"
                            @click="SuaLoaiHoaDon(data.data)"
                            hint="Sửa"
                            v-if="
                                $Helper.Common.CheckRole(
                                    $t('Quyen.CauHinh'),
                                    $t('Quyen.ThemSuaLoaiHoaDonDoanhNghiepSuDung')
                                )
                            "
                        ></DxButton>
                        <DxButton
                            id=""
                            icon="mdi mdi-delete"
                            class="mx-2"
                            @click="XoaLoaiHoaDon(data.data)"
                            hint="Xóa"
                            v-if="
                                $Helper.Common.CheckRole(
                                    $t('Quyen.CauHinh'),
                                    $t('Quyen.XoaLoaiHoaDonDoanhNghiepSuDung')
                                )
                            "
                        ></DxButton>
                    </div>
                </template>
            </DxDataGrid>
        </div>
    </div>
    <DxPopup
        v-model:visible="popupXacNhanXoa"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-close-button="false"
        :show-title="false"
        :width="300"
        height="auto"
    >
        <PopupXacNhan :Params="paramXacNhanXoa" @click="XacNhanXoa" />
    </DxPopup>
</template>

<script>
import { mapState } from "vuex";

import {
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxSummary,
    DxFilterRow,
    DxTotalItem,
    DxScrolling,
    DxRequiredRule,
} from "devextreme-vue/data-grid";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import routerVue from "@sonphat/common-v1/components/router.vue";
import {
    DxButton,
    DxPopup,
    DxTextBox,
    DxValidator,
    DxValidationGroup,
} from "devextreme-vue";
import PopupXacNhan from "@sonphat/common-v1/components/PopupXacNhan.vue";
export default {
    components: {
        DxValidationGroup,
        DxToolbar,
        DxItem,
        routerVue,
        DxButton,
        DxDataGrid,
        DxColumn,
        DxSelection,
        DxFilterRow,
        DxSummary,
        DxTotalItem,
        DxPopup,
        PopupXacNhan,
        DxTextBox,
        DxScrolling,
        DxValidator,
        DxRequiredRule,
    },

    data() {
        return {
            LyDoXoa: "",
            DataXoa: null,
            popupXacNhanXoa: false,
            arrSelected: [],
            paramXacNhanXoa: {
                WidthTitle: "90%",
                Title: "Xác nhận xóa hóa đơn bến xe sử dụng",
                btnLeft: "HỦY",
                btnRight: "ĐỒNG Ý",
            },
            breadcrumbsItems: [
                {
                    id: "breadcrums_DieuDo",
                    text: "Danh sách loại hóa đơn bến xe sử dụng",
                    disabled: true,
                    to: "",
                },
                // {
                //     id: "breadcrums_DieuDo",
                //     text: this.$t("stringRouter.QLBD"),
                //     disabled: true,
                //     to: "",
                // },
            ],
        };
    },
    created() {
        this.LayDanhSachLoaiHoaDonDoanhNghiepSuDung();
    },

    computed: {
        ...mapState({
            DanhSachLoaiHoaDonDoanhNghiepSuDung: (state) =>
                state.CongHoaDonDienTu.DanhSachLoaiHoaDonDoanhNghiepSuDung,
        }),
    },

    methods: {
        ThongBao(type, message) {
            let modelToast = {
                isVisible: true,
                message: message,
                type: type,
            };
            this.emitter.emit("onToast", modelToast);
        },
        loadData() {
            this.LayDanhSachLoaiHoaDonDoanhNghiepSuDung();
        },
        async LayDanhSachLoaiHoaDonDoanhNghiepSuDung() {
            try {
                this.$startLoading;
                let rs = await this.$store.dispatch(
                    "CongHoaDonDienTu/LayDanhSachLoaiHoaDonDoanhNghiepSuDung",
                    {
                        guidDoanhNghiep: this.$Core.Auth.TokenParsed.ID_BenXeSuDung,
                    }
                );
                this.$stopLoading;
                if (!rs.status) {
                } else {
                }
            } catch (error) {
                this.ThongBao(
                    "error",
                    `Lấy danh sách loại hóa đơn bến xe sử dụng  thất bại! Lỗi hệ thống`
                );
                this.$stopLoading;
            }
        },
        exportExcel() {
            let init = {
                Element: this.$refs.DanhSachLoaiHoaDon.instance,
                FileName: "Danh-Sach-Loai-Hoa-Don-Ben-Xe-Su-Dung",
                WorkSheet: "Danh Sách",
            };
            this.$Helper.Common.excel(init).Export();
        },

        onCellHoverChanged(e) {
            this.$Helper.Common.table({ Element: e, Buttons: true }).HoverRow();
        },
        onSelectionChanged(e) {
            this.arrSelected = e.selectedRowsData;
        },
        ThemLoaiHoaDon() {
            this.$router.push("/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung/Cap-Nhat-Loai-Hoa-Don");
        },
        SuaLoaiHoaDon(data) {
            this.$router.push({
                path: "/Loai-Hoa-Don-Doanh-Nghiep-Su-Dung/Cap-Nhat-Loai-Hoa-Don",
                query: {
                    guidLoaiHoaDon: data.guidLoaiHoaDon,
                },
            });
            console.log(data);
        },
        XoaLoaiHoaDon(data) {
            this.DataXoa = data;
            console.log(this.DataXoa);
            this.popupXacNhanXoa = true;
        },

        async XacNhanXoa(bool) {
            if (bool == true) {
                this.popupXacNhanXoa = false;
                try {
                    let rs = await this.$store.dispatch(
                        "CongHoaDonDienTu/XoaLoaiHoaDon",
                        {
                            guidCauHinh: this.DataXoa.guidCauHinh,
                        }
                    );
                    if (!rs.status) {
                        this.ThongBao("error", `xóa thất bại! ${rs.message}`);
                    } else {
                        this.ThongBao("success", `Xóa thành công!`);
                        this.LayDanhSachLoaiHoaDonDoanhNghiepSuDung();
                        // this.$refs.DanhSachLoaiHoaDonDoanhNghiepSuDung.instance.refresh();
                    }
                } catch (error) {
                    this.$stopLoading;
                }
            } else if (bool == false) {
                // Huy
                this.popupXacNhanXoa = false;
            }
            this.DataXoa = null;
        },
    },
};
</script>

<style scope>
/* .table-page
    .dx-datagrid-rowsview
    .dx-row.dx-data-row.dx-row-lines
    > td[aria-colindex="3"],
td[aria-colindex="4"],
td[aria-colindex="5"] {
    text-align: right !important;
} */
</style>
